@import './defaultVariable'


.default
  color: $default !important
.green
  color: $green !important
.blue
  color: $blue !important
.purple
  color: $purple !important
.gray
  color: $gray !important
.deepblue
  color: $deepblue !important

.bg_color_default
  background-color: $default !important
.bg_color_green
  background-color: $green !important
.bg_color_blue
  background-color: $blue !important
.bg_color_purple
  background-color: $purple !important
.bg_color_gray
  background-color: $gray !important
.bg_color_deepblue
  background-color: $deepblue !important

.border_color_default
  border: 1px solid $default !important
.border_color_green
  border: 1px solid $green !important
.border_color_blue
  border: 1px solid $blue !important
.border_color_purple
  border: 1px solid $purple !important
.border_color_gray
  border: 1px solid $gray !important
.border_color_deepblue
  border: 1px solid $deepblue !important
