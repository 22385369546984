body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
p {
  -webkit-hyphens: auto;
  -webkit-hyphenate-limit-before: 3;
  -webkit-hyphenate-limit-after: 3;
  -webkit-hyphenate-limit-chars: 6 3 3;
  -webkit-hyphenate-limit-lines: 2;
  -webkit-hyphenate-limit-last: always;
  -webkit-hyphenate-limit-zone: 8%;

  -moz-hyphens: auto;
  -moz-hyphenate-limit-chars: 6 3 3;
  -moz-hyphenate-limit-lines: 2;
  -moz-hyphenate-limit-last: always;
  -moz-hyphenate-limit-zone: 8%;

  -ms-hyphens: auto;
  -ms-hyphenate-limit-chars: 6 3 3;
  -ms-hyphenate-limit-lines: 2;
  -ms-hyphenate-limit-last: always;
  -ms-hyphenate-limit-zone: 8%;

  hyphens: auto;
  hyphenate-limit-chars: 6 3 3;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;
  hyphenate-limit-zone: 8%;
  word-wrap: break-word;
  word-break: break-all;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@keyframes loading {
  0% {
    transform: rotateZ(0deg);
  }
  100%{
    transform: rotateZ(360deg);
  }
}

.load {
  animation: loading 1s linear infinite;
}

.data-title:hover::after{
  content: attr(title);
  position: absolute;
  top: -100%;
  left: 0;
}
.MuiNativeSelect-root:focus {
  background-color: transparent !important;
}
.MuiInput-underline:before {
  border-bottom: none !important;
}

.MuiNativeSelect-select {
  position: absolute;
  top: 7px;
  left: 10px
}

.MuiInputBase-input {
  height: 40px !important;
  padding: 0 15px !important;
}
.MuiInputLabel-outlined {
  transform: translate(14px, 12px) scale(1) !important;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink{
  transform: translate(14px, -6px) scale(0.75) !important;
}

.page_comments_btn, .page_remove_btn,.page_add_btn,.block_comments_btn,.block_remove_btn,.block_add_btn {
  opacity: 0;
  user-select: none;
  pointer-events: none;
  transition: all 0.3s linear;
}
.comments_wrapper_show {
  opacity: 1;
  pointer-events: all;
}

.drag_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.drag-content{
  position: absolute;
  min-width: 5000px;
  min-height: 5000px;
  width: fit-content;
  height: fit-content;
}

